import React from "react";
import {connect} from "react-redux";
import {AiOutlineInstagram} from "react-icons/ai";
import {DataReporter} from "../../DataReporter";
import "../../styles/components/search_tesimonials.scss";

class TestimonialsSection extends React.Component {
    onClickIGLink(url) {
        DataReporter.trackMixpanel(this.props, "Clicked on testimonial",
            {
                category: "Interaction",
                url: url
            });

        setTimeout(() => {
            window.open(url);
        }, 300);
    }

    render() {
        let mobileSuffix = this.props.forMobile ? "-mobile" : "";

        return (
            <div id={"testimonials-container" + mobileSuffix}>
                <div id={"content"}>

                    <p id={"headline"}>
                       Positively Reviewed by Users
                    </p>

                    <div id={"sub-content"}>
                        <div className={"testimonial"} id={"testimonial-1"}>
                            <div className={"text"}>
                                <div className={"inline"}>
                                    <p className={"q1"}>“</p>
                                    <p className={"quote"}>
                                        If you’re someone who looks for good deals or just wants an accurate
                                        history of a product before you buy, then you’re going to love Ventrace.
                                    </p>
                                </div>
                            </div>

                            <div className={"profile-info"}>
                                <div className={"profile-pic-container"}>
                                    <img
                                        className={"profile-pic"}
                                        src={process.env.PUBLIC_URL + '/assets/testimonials/Calum.jpg'}
                                        alt={""}
                                    />
                                </div>
                                <div className={"profile-text"}>
                                    <p className={"name"}>Calum</p>
                                    <div className={"inline sm-icons"}>
                                        <AiOutlineInstagram className={"ref-sm-icon"}/>
                                        <p className={"account"}
                                            onClick={() =>
                                                this.onClickIGLink("https://www.instagram.com/calumhurn/")}>
                                            calumhurn</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className={"testimonial"}>
                            <div className={"text"}>
                                <div className={"inline"}>
                                    <p className={"q1"}>“</p>
                                    <p className={"quote"}>
                                        Ventrace is really exciting, since it offers a groundbreaking solution to
                                        protect your own valuable products. I can also verify the quality of products
                                        which are coming from overseas.
                                    </p>
                                </div>
                            </div>

                            <div className={"profile-info"}>
                                <div className={"profile-pic-container"}>
                                    <img
                                        className={"profile-pic"}
                                        src={process.env.PUBLIC_URL + '/assets/testimonials/Diana.jpg'}
                                        alt={""}
                                    />
                                </div>
                                <div className={"profile-text"}>
                                    <p className={"name"}>Diana</p>
                                    <div className={"inline sm-icons"}>
                                        <AiOutlineInstagram className={"ref-sm-icon"}/>
                                        <p className={"account"}
                                           onClick={() =>
                                               this.onClickIGLink("https://www.instagram.com/diana_or_diane/")}>
                                            diana_or_diane</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>



                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        appReducer: state.app
    }
}

export default connect(mapStateToProps, null)(TestimonialsSection);