import React from "react";
import {connect} from "react-redux";
import appConfig from "../appConfig";
import Navigation from "../components/Navigation";
import Footer from "../components/Footer";
import {DataReporter} from "../DataReporter";
import {AiFillPhone, AiOutlineMail} from "react-icons/ai";
import {isMobile} from "react-device-detect";
import { FaRegFilePdf } from "react-icons/fa6";
import "../styles/pages/providers.scss";

class Providers extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    componentDidMount() {
        DataReporter.trackMixpanel(this.props,
            "Page view: Providers",
            {
                pageDisplayMode: this.props.appReducer.mobileMode ? "mobile" : "desktop"
            });
    }

    // Events
    onEmailClick() {
        DataReporter.trackMixpanel(this.props, "Contact via mail", {
            category: "Interaction"
        });
        window.location.href = `mailto:provider@ventrace.com`;
    }

    onDownloadProviderSlidesEN() {
        DataReporter.trackMixpanel(this.props, "Download Provider Slides EN", {
            category: "Interaction"}
        );

        setTimeout(() => {
            window.location.href = process.env.PUBLIC_URL + '/docs/Ventrace_Platform_Providers_EN_Quick.pdf';
        }, 300);
    }

    onDownloadProviderSlidesDE() {
        DataReporter.trackMixpanel(this.props, "Download Provider Slides DE");
        setTimeout(() => {
            window.location.href = process.env.PUBLIC_URL + '/docs/Ventrace_Platform_Providers_DE_Quick.pdf';
        }, 300);
    }

    onDownloadPlatformExplainer() {
        DataReporter.trackMixpanel(this.props, "Download Platform Explainer");
        setTimeout(() => {
            window.location.href = process.env.PUBLIC_URL + '/docs/Ventrace Platform Explainer v1_EN.pdf';
        }, 300);
    }

    onDownloadIntegrationGuide() {
        DataReporter.trackMixpanel(this.props, "Download Integration Guide");
        setTimeout(() => {
            window.location.href = process.env.PUBLIC_URL + '/docs/Ventrace Integration Guide v1_EN.pdf';
        }, 300);
    }

    // Renderers
    renderContactSection() {
        let phoneNr = this.props.appReducer.supportPhoneNrs.USA;
        if (this.props.appReducer.userRegionInEurope) {
            phoneNr = this.props.appReducer.supportPhoneNrs.Europe;
        }

        return (
            <div id={"provider-contact-container"}>
                <h2>Get in touch now</h2>
                <p id={"descr"}>
                    We're looking forward to work with you. Let's get in touch!
                </p>

                <div className={"inline"}>
                    <div id={"phone-box-container"}>
                        <p id={"h"}>Contact us per phone</p>
                        <p id={"phone-nr"}><AiFillPhone id={"phone-icon"}/> {phoneNr}</p>
                        <p id={"d"}>
                            This is a free service phone number. However, your phone service
                            provider may generally charge you for outgoing calls.
                        </p>
                    </div>

                    <div id={"email-box"}>
                        <div id={"descr-container"} onClick={() => this.onEmailClick()}>
                            <div className={"inline"}>
                                <AiOutlineMail id={"icon"} onClick={() => this.onEmailClick()}/>
                                <p id={"h"}>Contact per email</p>
                            </div>
                            <p id={"descr"}>
                                Send your question per mail and get help directly.
                                You can expect to get an answer within 1-2 days.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    renderMainMessageSection() {
        return (
            <div id={"message-container"}>
                <h2>Revolutionize Your Watch Business with Ventrace</h2>
                <h3>The Future of Luxury Watch Ownership</h3>

                <p id={"descr"}>
                    Boost Customer Satisfaction | Enhance Brand Trust | Generate New Revenue
                </p>

                <img id={"vr-on-phone-img"}
                     src={process.env.PUBLIC_URL + '/providers/vr-on-phone.jpg'}
                />

            </div>
        );
    }

    renderResources() {
        return (
            <div id={"resources-container"}>
                <h2>Resources</h2>
                <p id={"descr"}>
                    Do you need more details? Take a look at the documents below to find your answers.
                </p>

                <div id={"resource-items"}>
                    <div className={"res-item"} onClick={() => this.onDownloadProviderSlidesEN()}>
                        <FaRegFilePdf className={"pdf-icon"} />
                        <p className={"resource-name"}>
                            Slides: Ventrace for Providers (English)
                        </p>
                    </div>

                    <div className={"res-item"} onClick={() => this.onDownloadProviderSlidesDE()}>
                        <FaRegFilePdf className={"pdf-icon"} />
                        <p className={"resource-name"}>
                            Slides: Ventrace für Provider (Deutsch)
                        </p>
                    </div>

                    <div className={"res-item"} onClick={() => this.onDownloadPlatformExplainer()}>
                        <FaRegFilePdf className={"pdf-icon"} />
                        <p className={"resource-name"}>
                            Ventrace Platform Explainer (v1) - English
                        </p>
                    </div>

                    <div className={"res-item"} onClick={() => this.onDownloadIntegrationGuide()}>
                        <FaRegFilePdf className={"pdf-icon"} />
                        <p className={"resource-name"}>
                            Ventrace Integration Guide (v1) - English
                        </p>
                    </div>

                </div>
            </div>
        );
    }

    render() {
        let mobileSuffix = isMobile ? "-mobile" : "";
        document.title = "Ventrace - Providers";

        return (
            <div>
                <Navigation />
                <div className={"content-container" + mobileSuffix}
                     id={"provider-container"+ mobileSuffix}>
                    <h1>For Providers</h1>

                    {this.renderMainMessageSection()}
                    {this.renderContactSection()}
                    {this.renderResources()}
                </div>
                <Footer />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        appReducer: state.app,
    }
}

export default connect(mapStateToProps, null)(Providers);