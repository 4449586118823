import React from "react";
import {connect} from "react-redux";
import appConfig from "../appConfig";
import axios from "axios";
import {DataReporter} from "../DataReporter";
import {DateConverter} from "../DateConverter";
import Navigation from "../components/Navigation";
import Footer from "../components/Footer";
import FeedbackMessage from "../components/FeedbackMessage";
import TextInput from "../components/inputs/TextInput";
import TextArea from "../components/inputs/TextArea";
import HintContainer from "../components/HintContainer";
import FormStepsIndicator from "../components/FormStepsIndicator";
import RecordCheckout from "../components/RecordCheckout";
import DatePicker from "react-datepicker";
import ImageUploadInput from "../components/inputs/ImageUploadInput";
import OrderSuccess from "../components/OrderSuccess";
import CheckboxInput from "../components/inputs/CheckboxInput";
import UserButton from "../components/inputs/UserButton";
import { FaChevronRight } from "react-icons/fa";
import {BsCalendarCheck} from "react-icons/bs";
import {AiFillLock} from "react-icons/ai";
import {requestSetCurrentPage, requestSetOrderData} from "../store/actions/app";
import {RiShieldCheckFill} from "react-icons/ri";
import {BiMessageAltAdd} from "react-icons/bi";
import {HiOutlineGlobeAlt} from "react-icons/hi";
import {GoEyeClosed} from "react-icons/go";
import { RiShoppingCartLine } from "react-icons/ri";
import "react-datepicker/dist/react-datepicker.css";
import "../styles/pages/order_record.scss";

global.Buffer = global.Buffer || require('buffer').Buffer;

class OrderRecord extends React.Component {
    constructor(props) {
        super(props);

        let freshOrder = new URLSearchParams(this.props.location.search).get("f")
        let appReducer = this.props.appReducer;

        let createName = null;
        let createDescr = null;
        let createSNr = null;
        let createPurchaseDateValid = true;

        if (appReducer.claimingRecord) {
            createName = appReducer.claimingRecord.rootRecordData.name;
            createDescr = appReducer.claimingRecord.rootRecordData.description;
            createSNr = appReducer.claimingRecord.rootRecordData.serialNr;
            createPurchaseDateValid = false;
        }
        else if (appReducer.orderData && freshOrder !== "1") {
            createName = appReducer.orderData.createName;
            createDescr = appReducer.orderData.createDescr;
            createSNr = appReducer.orderData.createSNr;
            createPurchaseDateValid = false;
        }

        this.state = {
            minInputLength: 5,
            productNameExample: this.getProductNameExample(),
            createName: createName,
            createNameValid: true,
            createDescr: createDescr,
            createDescrValid: true,
            createPurchaseDate: Date.now(),
            createSNr: createSNr,
            createSNrValid: true,
            createSNrValidationMsg: null,
            createImageFiles: null,
            createImageCID: null,
            createPurchaseDateValid: createPurchaseDateValid,
            createPurchaseDateValidMsg: "Please re-check this date",
            imageUploadValidationErr: null,
            imageUploadValidationMsg: null,
            submissionSuccessful: null,
            submittingRecord: false,
            estimatedGasUse: null,
            errorMessage: null,
            formIsValid: false,
            checkoutSuccess: false,
            orderRP: null,
            redirectChecking: false, // true if this page is opened via payment redirect URL,
            checkoutErrorMessage: null,
            detailsEntered: false,
            optionsSelected: false,
            addPremiumPackage: false,
            selectedCurrency: this.props.appReducer.userRegionInEurope ? "EUR" : "USD",
        }

        this.orderFormRef = React.createRef();
    }

    getProductWord(capitalize) {
        let productWord = "product";
        if (this.props.appReducer.activePersona === appConfig.personas.watches) {
            productWord = "watch";
        }
        if (capitalize) {
            return productWord.charAt(0).toUpperCase() + productWord.slice(1);
        } else {
            return productWord;
        }
    }

    getProductNameExample() {
        let examples = [
            "Apple iPhone 15 Pro, 256 GB, Blue",
            "Lenovo ThinkPad T14, 512 GB SSD",
            "Samsung Crystal UHD TV 4K 75\" 2023"
        ];

        if (this.props.appReducer.activePersona === appConfig.personas.watches) {
            examples = [
                "Rolex Submariner Date (Starbucks)",
                "Omega Speedmaster (Black) 2024",
                "Breitling Navitimer (Automatic 41)"
            ];
        }

        return examples[Math.floor(Math.random() * examples.length)];
    }

    getRecordJSON(imageCIDs) {
        if (this.state.createName && this.state.createDescr && this.state.createSNr) {
            return {
                formatVersion: 1,
                recordCreationDate: Date.now().toString(),
                recordType: "rootRecord",
                name: this.state.createName.trim(),
                description: this.state.createDescr.trim(),
                imageCIDs: imageCIDs,
                serialNr: this.state.createSNr.trim(),
                purchaseDate: this.state.createPurchaseDate
            }
        }
        return null;
    }

    getOrderStatus(orderID) {
        try {
            axios
                .get(appConfig.currentConfig.backendApp.url + "/order/statusupdate?orderID=" + orderID,
                    {
                        headers: {
                            Authorization: `Bearer ${appConfig.currentConfig.backendApp.tokens.standard}`,
                            'Content-Type': 'application/json',
                        },
                    })
                .then(res => {
                    if (res.status === 200) {
                        let s = res.data.status;
                        let statusIsFinal = (s === "paid" || s === "expired" || s === "failed" || s === "canceled")

                        if (statusIsFinal) {
                            if (s === "paid") {
                                this.setState({
                                    checkoutSuccess: true,
                                    orderRP: res.data.rp,
                                    redirectChecking: false
                                }, () => {
                                    this.props.dispatchSetOrderData(null);
                                });
                            } else {
                                // TODO: Free root record
                                this.setState({
                                    checkoutErrorMessage: "The payment has failed. Please try again.",
                                    redirectChecking: false,
                                }, () => {
                                    DataReporter.trackMixpanel(this.props, "orderStatusFail", {
                                        category: "Payment",
                                        failReason: s
                                    })
                                });
                            }

                        } else {
                            setTimeout(() => this.getOrderStatus(orderID), 4000);
                        }
                    } else {
                        this.setState({
                            checkoutErrorMessage: "The payment has failed. Please try again.",
                        });
                    }
                })
                .catch(err => {
                    DataReporter.trackSentry(err,
                        { extra: {additionalData: "checkRedirectedOrder 01"} });
                    this.setState({
                        redirectChecking: false
                    });
                })

        } catch (err) {
            DataReporter.trackSentry(err,
                { extra: {additionalData: "checkRedirectedOrder 02"} });
            this.setState({
                redirectChecking: false,
                errorMessage: "Can't confirm payment. Please contact support."
            });
        }
    }

    validateForm() {
        let canSave = true;
        this.setState({
            createNameValid: true,
            createDescrValid: true,
            createSNrValid: true,
            createSNrValidationMsg: null,
            showValidErrorHint: false,
            imageUploadValidationErr: null,
        }, () => {
            DataReporter.trackMixpanel(this.props, "Order: Validating Record");
        });

        if (this.state.createName === null || this.state.createName.length < this.state.minInputLength) {
            this.setState({
                createNameValid: false
            }, () => {
                DataReporter.trackMixpanel(this.props, "Order: Validation Error: Name too short")
            });

            canSave = false;
        }

        if (this.state.createDescr === null || this.state.createDescr.length < this.state.minInputLength) {
            this.setState({
                createDescrValid: false
            }, () => {
                DataReporter.trackMixpanel(this.props, "Order: Validation Error: Description too short");
            });
            canSave = false;
        }

        if (this.state.createSNr === null || this.state.createSNr.length < this.state.minInputLength) {
            this.setState({
                createSNrValid: false,
                createSNrValidationMsg: "The given serial number is too short."
            }, () => {
                DataReporter.trackMixpanel(this.props, "Order Validation Error: Serial Nr too short");
            });
            canSave = false;
        }

        let regex = /^[a-zA-Z0-9-]+$/;
        if (!regex.test(this.state.createSNr)) {
            this.setState({
                createSNrValid: false,
                createSNrValidationMsg: "The serial number cannot have any special characters (including spaces)."
            }, () => {
                DataReporter.trackMixpanel(this.props, "Order Validating Error: SNR has special characters");
            });
            canSave = false;
        }

        if (this.state.createImageFiles === null || this.state.createImageFiles.length < 2) {
            this.setState({
                imageUploadValidationErr: true,
                imageUploadValidationMsg: `You need to attach at least 2 images (of ${this.getProductWord()} or receipt).`,
            }, () => {

                DataReporter.trackMixpanel(this.props,
                    `Order Validation Error: Not enough images attached`);
            });
            canSave = false;
        }

        this.setState({
            formIsValid: canSave,
            showValidErrorHint: !canSave
        });
        return canSave;
    }

    getDescriptionHints() {
        if (this.props.appReducer.activePersona === appConfig.personas.watches) {
            return [
                "Describe your watch in detail",
                "What type of watch (e. g. diver, chronograph, etc.)",
                "Which movement, what type of bracelet",
            ];
        } else {
            return [
                `The general description of the product (color, size, etc.).`,
            ];
        }
    }

    checkRedirectedOrder() {
        let orderID = new URLSearchParams(this.props.location.search).get("oid");

        if (orderID) {
            if (appConfig.onDev) {
                console.log("INFO: Execute Postman endpoint call to apply dev-mode payments.");
            }
            this.setState({
                redirectChecking: true,
                createPurchaseDateValid: true,
                createPurchaseDateValidMsg: null
            }, () => {
                setTimeout(() => {
                    let targetYPosition = this.orderFormRef.current.getBoundingClientRect().top;

                    window.scrollTo({
                        top: window.scrollY + (targetYPosition - 300),
                        behavior: 'smooth',
                    });
                }, 200);

                this.getOrderStatus(orderID);
            })
        }
    }

    checkOrderReset() {
        let freshOrder = new URLSearchParams(this.props.location.search).get("f");
        if (freshOrder === "1") {
            this.props.dispatchSetOrderData(null);
        }
    }

    getOrderStepNumber() {
        if (this.state.optionsSelected || this.state.redirectChecking) {
            return 3;
        } else if (this.state.detailsEntered) {
            return 2;
        } else {
            return 1;
        }
    }

    saveCurrentOrderData() {
        this.props.dispatchSetOrderData({
            createName: this.state.createName,
            createDescr: this.state.createDescr ? this.state.createDescr : null,
            createSNr: this.state.createSNr ? this.state.createSNr : null,
            premiumPackageSelected: this.state.addPremiumPackage
        });
    }

    // Events
    componentDidMount() {
        this.props.dispatchSetPage("order");
        DataReporter.trackMixpanel(this.props,
            "Page view: Order Record",
            {
                pageDisplayMode: this.props.appReducer.mobileMode ? "mobile" : "desktop",
                trigger: new URLSearchParams(this.props.location.search).get("t")
            });

        this.checkOrderReset();
        this.checkRedirectedOrder();
    }

    nameOnChange(e) {
        if (e.inputValue.length >= this.state.minInputLength) {
            this.setState({createName: e.inputValue});
        }
    }

    nameOnPaste(e) {
        this.nameOnChange({inputValue: e.clipboardData.getData("text")});
    }

    descrOnChange(e) {
        if (e.inputValue.length >= this.state.minInputLength) {
            this.setState({createDescr: e.inputValue});
        }
    }

    descrOnPaste(e) {
        this.descrOnChange({inputValue: e.clipboardData.getData("text")});
    }

    purchaseDateOnChange(date) {
        this.setState({
            createPurchaseDate: DateConverter.getUTCMilliseconds(date)
        }, () => {
            DataReporter.trackMixpanel(this.props, "Order: Changed date", {
                category: "Interaction"
            });
        });
    }

    serialNrOnChange(e) {
        if (e.inputValue.length >= this.state.minInputLength) {
            this.setState({createSNr: e.inputValue});
        }
    }

    serialNrOnPaste(e) {
        this.serialNrOnChange({inputValue: e.clipboardData.getData("text")});
    }

    createImageFileOnChange(e) {
        let a = [];
        for (let i = 0; i < e.length; i += 1) {
            if (e[i] !== null) a.push(e[i]);
        }
        this.setState({
            createImageFiles: a
        }, () => {
            DataReporter.trackMixpanel(this.props, "Order: Changed image", {
                category: "Interaction",
                value: e.length
            });
        });
    }

    resetImageFile(e) {
        this.setState({createImageFile: null});
    }

    onCheckoutSuccess(prp) {
        this.setState({
            checkoutSuccess: true,
            orderRP: prp
        });
    }

    onContinueAfterDetails() {
        if (this.validateForm()) {
            this.setState({
                detailsEntered: true
            }, () => {
                this.saveCurrentOrderData();
                DataReporter.trackMixpanel(this.props, "Order: Details completed");

                let scrollDelta = 100;
                if (this.props.appReducer.mobileMode) scrollDelta = 0;

                window.scrollTo({
                    top: scrollDelta,
                    behavior: 'smooth',
                });
            })
        }
    }

    onContinueAfterOptions() {
        this.setState({
            optionsSelected: true
        }, () => {
            let scrollDelta = 100;
            if (this.props.appReducer.mobileMode) scrollDelta = 0;

            window.scrollTo({
                top: scrollDelta,
                behavior: 'smooth',
            });

            this.saveCurrentOrderData();
            DataReporter.trackMixpanel(this.props, "Order: Options completed");
        })
    }

    onAddPremiumPackage(isChecked) {
        this.setState({
            addPremiumPackage: !this.state.addPremiumPackage
        }, () => {
            DataReporter.trackMixpanel(this.props,
                isChecked ? "Order: Add premium package" : "Order: Remove premium package");
        })
    }

    onStepBackToDetails() {
        this.setState({
            detailsEntered: false,
            optionsSelected: false
        }, () => {
            DataReporter.trackMixpanel(this.props, "Order: Back to details");
        });
    }

    // Renderers
    renderErrorMessage() {
        if (this.state.errorMessage !== null) {
            return <FeedbackMessage
                success={false}
                message={this.state.errorMessage}
            />;
        }
    }

    renderDateValidation() {
        if (!this.state.createPurchaseDateValid) {
            return (
                <div className={"input-error"} id={"purchase-date-validation"}>
                    <p className={"msg"}>
                        {this.state.createPurchaseDateValidMsg}
                    </p>
                </div>
            )
        }
    }

    renderShoppingCart() {
        if (this.getOrderStepNumber() !== 3) {
            let currency = this.props.appReducer.userRegionInEurope ? "EUR" : "USD"
            let price = this.props.appReducer.VRprices.standard;
            let premiumText = "";

            if (this.state.addPremiumPackage) {
                price += this.props.appReducer.VRprices.premium;
                premiumText = "(Premium)"
            }

            return (
                <div id={"shopping-cart-container"}>
                    <div className={"inline"}>
                        <RiShoppingCartLine id={"icon"} />
                        <div id={"contents"}>
                            <div className={"inline"}>
                                <p id={"quantity"}>1</p>
                                <div>
                                    <p id={"product"}>Ventrace Record {premiumText}</p>
                                    <p id={"vr-descr"}>
                                        Digital watch certificate
                                    </p>
                                </div>
                            </div>
                        </div>
                        <p id={"price"}>
                            {currency === "USD" ? "$" : ""}
                            {price.toFixed(2)}
                            {currency === "EUR" ? "€" : ""}
                        </p>
                    </div>
                </div>
            );
        }
    }

    renderDetailsForm() {
        let validationMsg = `This field can't be empty.`;
        let minDate = new Date();
        minDate.setFullYear(minDate.getFullYear() - 30);

        let checkForm;
        if (this.state.showValidErrorHint) {
            checkForm = (
                <div className={"input-error"} id={"submit-error"}>
                    <p className={"msg"}>
                        You've missed some details. Please check above.
                    </p>
                </div>
            );
        }

        return (
            <div id={"details-form-container"}>
                <p id={"details-h"}>Details about your {this.getProductWord()}</p>
                <p>
                    Please enter the details of your {this.getProductWord()}, for which you want
                    to order a Ventrace Record.
                </p>

                <div id={"product-name-inputs-container"}>
                    <TextInput
                        inputID={"product-name-input"}
                        label={`${this.getProductWord(true)} Name`}
                        value={this.state.createName}
                        placeholder={`${this.getProductWord(true)} Name`}
                        onChange={(e) => this.nameOnChange(e)}
                        onPaste={(e) => this.nameOnPaste(e)}
                        validationError={!this.state.createNameValid}
                        validationMsg={validationMsg}
                        maxLen={50}
                        hints={
                            <HintContainer
                                forMobile={this.props.appReducer.mobileMode}
                                hints={[
                                    "Mention the brand, the model and further specifics.",
                                    `Example: ${this.state.productNameExample}.`,
                                ]}
                            />
                        }
                    />
                </div>

                <div id={"product-descr-inputs-container"}>
                    <TextArea
                        id={"product-descr-input"}
                        label={`${this.getProductWord(true)} Description`}
                        value={this.state.createDescr}
                        maxLen={500}
                        rows={3}
                        placeholder={`${this.getProductWord(true)} Description`}
                        onChange={(e) => this.descrOnChange(e)}
                        onPaste={(e) => this.descrOnPaste(e)}
                        validationError={!this.state.createDescrValid}
                        validationMsg={validationMsg}
                        hints={
                            <HintContainer
                                forMobile={this.props.appReducer.mobileMode}
                                id={"product-descr-hints-mobile"}
                                hints={this.getDescriptionHints()}
                            />
                        }
                    />
                </div>

                <div id={"purchase-date-input-container"}>
                    <p className={"input-label"}>
                        <BsCalendarCheck className={"label-icon"}/>
                        Purchase Date
                    </p>
                    <DatePicker
                        peekNextMonth
                        showMonthDropdown
                        showYearDropdown
                        yearDropdownItemNumber={20}
                        dropdownMode="select"
                        dateFormat="yyyy-MM-dd"
                        className={"purchase-date-input"}
                        selected={this.state.createPurchaseDate}
                        onChange={(date) => this.purchaseDateOnChange(date)}
                        minDate={minDate}
                        maxDate={new Date()}
                    />
                    {this.renderDateValidation()}
                </div>

                <div id={"product-uid-input-container"}>
                    <TextInput
                        inputID={"product-uid-input"}
                        label={`${this.getProductWord(true)} Serial Number`}
                        value={this.state.createSNr}
                        disabled={this.props.appReducer.claimingRecord}
                        placeholder={`${this.getProductWord(true)} Serial Number`}
                        onChange={(e) => this.serialNrOnChange(e)}
                        onPaste={(e) => this.serialNrOnPaste(e)}
                        validationError={!this.state.createSNrValid}
                        validationMsg={this.state.createSNrValidationMsg}
                        maxLen={50}
                        hints={
                            <HintContainer
                                forMobile={this.props.appReducer.mobileMode}
                                id={"product-uid-hints-mobile"}
                                hints={[
                                    `The unique serial number of your ${this.getProductWord()}.`,
                                    `You can find the serial number at the back side of your ${this.getProductWord()}.`
                                ]}
                            />
                        }
                    />
                </div>

                <div id={"image-upload-container"}>
                    <p className={"input-label"}>
                        {`${this.getProductWord(true)} Images (at least 2)`}
                    </p>
                    <ImageUploadInput
                        forMobile={this.props.appReducer.mobileMode}
                        onChange={(e) => this.createImageFileOnChange(e)}
                        onReset={() => this.resetImageFile()}
                        validationError={this.state.imageUploadValidationErr}
                        validationMsg={this.state.imageUploadValidationMsg}
                        hints={
                            <HintContainer
                                forMobile={this.props.appReducer.mobileMode}
                                id={"product-imgs-hints-mobile"}
                                hints={[
                                    `Try to upload a picture which shows the whole ${this.getProductWord()}.`,
                                    "If possible, also try to upload a picture showing the serial number."
                                ]}
                            />
                        }
                    />
                </div>

                <UserButton
                    forMobile={this.props.appReducer.mobileMode}
                    id={"continue-details-button"}
                    value={"Continue to options"}
                    icon={<FaChevronRight className={"text-icon"}/>}
                    onClick={() => this.onContinueAfterDetails()}
                />
                {checkForm}
            </div>
        )
    }

    renderPremiumFeatures() {
        let price = null;
        if (this.state.selectedCurrency === "EUR") {
            price = `${this.props.appReducer.VRprices.premium}€`;
        } else {
            price = `$${this.props.appReducer.VRprices.premium}`;
        }

        return (
            <div id={"premium-features-container"}>
                <div id={"header-container"}>
                    <p id={"premium-features-h"}>
                        Extra premium package
                    </p>
                    <p id={"price-tag"}>
                        {price}
                    </p>
                </div>

                {this.renderIncludedItem(
                    <GoEyeClosed id={"privacy-icon"}/>,
                    "Private mode option",
                    `Receive details about your ${this.getProductWord()} just for yourself, without publicly sharing your Record.`
                )}

                {this.renderIncludedItem(
                    <AiFillLock id={"claim-protection-icon"}/>,
                    "Claim protection",
                    null,
                    (
                        <p id={"description"}>
                            No one can replicate your Record and assert unwanted ownership of your {this.getProductWord()}.
                        </p>
                    )
                )}

                <CheckboxInput
                    id={"add-premium-cb"}
                    label={"Add the premium package to my Ventrace Record."}
                    onChange={(e) => this.onAddPremiumPackage(e)}
                    checked={this.state.addPremiumPackage}
                />
            </div>
        );
    }

    renderIncludedItem(icon, title, descr, customComponent = null) {
        let descrComp;
        if (descr) {
            descrComp = (<p id={"description"}>{descr}</p>);
        }

        return (
            <div className={"included-item"}>
                <div id={"h"}>
                    {icon}
                    <p id={"title"}>{title}</p>
                </div>
                {descrComp}
                {customComponent ? customComponent : null}
            </div>
        );
    }

    renderOptionsForm() {
        return (
            <div id={"options-container"}>
                <p id={"options-h"}>Order options</p>

                <div id={"whats-included-container"}>
                    <p id={"whats-included-h"}>
                        See what's included in your order
                    </p>

                    {this.renderIncludedItem(
                        <RiShieldCheckFill className={"icon"} id={"guarantee-icon"}/>,
                        "Instant money-back guarantee",
                        "You can get your money back easy and fast within 30 days of ordering."
                    )}

                    {this.renderIncludedItem(
                        <BiMessageAltAdd id={"auto-updates-icon"}/>,
                        `Automatic ${this.getProductWord()} updates`,
                        null,
                        (
                            <p id={"description"}>
                                Your Record can receive past and future details about the history and condition
                                of your {this.getProductWord()}.
                                <sup className={"note"}>*</sup>
                            </p>
                        )
                    )}

                    {this.renderIncludedItem(
                        <HiOutlineGlobeAlt className={"icon"} id={"recovery-system-icon"}/>,
                        "Global recovery system",
                        null,
                        (
                            <p id={"description"}>
                                In case of losing your {this.getProductWord()}, you can activate the Ventrace
                                recovery mode to find out where your watch is.<sup className={"note"}>**</sup>
                            </p>
                        )
                    )}

                </div>

                {this.renderPremiumFeatures()}

                <UserButton
                    forMobile={this.props.appReducer.mobileMode}
                    id={"continue-payment-button"}
                    value={"Continue to payment"}
                    icon={<FaChevronRight className={"text-icon"}/>}
                    onClick={() => this.onContinueAfterOptions()}
                />

                <p id={"step-back-text"} onClick={() => this.onStepBackToDetails()}>
                    Go back to details
                </p>

                <div id={"footnotes"}>
                    <p><sup>*</sup>Available only when information providers deliver these details to Ventrace.</p>
                    <p><sup>**</sup>Ventrace can only detect relevant activities on its own global platform.</p>
                </div>
            </div>
        );
    }

    renderForm() {
        if (this.state.submissionSuccessful === null && !this.state.checkoutSuccess) {
            let mobileSuffix = this.props.appReducer.mobileMode ? "-mobile" : "";

            let currentForm;
            if (this.state.optionsSelected || this.state.redirectChecking) {
                currentForm = (
                    <RecordCheckout
                        recordFormIsValid={this.state.formIsValid}
                        validateRecordForm={() => this.validateForm()}
                        rrData={this.getRecordJSON()}
                        imageFiles={this.state.createImageFiles}
                        onCheckoutSuccess={(prp) => this.onCheckoutSuccess(prp)}
                        redirectChecking={this.state.redirectChecking}
                        errorMessage={this.state.checkoutErrorMessage}
                        orderFormRef={this.orderFormRef}
                    />
                );
            } else if (this.state.detailsEntered) {
                currentForm = this.renderOptionsForm();
            } else {
                currentForm = this.renderDetailsForm();
            }

            return (
                <div className={"order-container" + mobileSuffix}>
                    <h1>Order your Ventrace Record</h1>

                    <FormStepsIndicator
                        activeStep={this.getOrderStepNumber()}
                        steps={["Watch details", "Options", "Payment method"]}
                    />

                    {this.renderShoppingCart()}

                    <div id={"form-container"}>
                        {currentForm}
                    </div>
                </div>
            )
        }
    }

    renderDocumentTitle() {
        if (this.state.createName && this.state.createName.length >= this.state.minInputLength)
            document.title = `Ventrace - ${this.state.createName}`;
        else document.title = "Ventrace - Order Record";
    }

    renderOrderSuccess() {
        if (this.state.checkoutSuccess) {
            document.title = "Ventrace - Order succcessful";
            return (
                <OrderSuccess
                    prp={this.state.orderRP}
                />
            );
        }
    }

    render() {
        let mobileSuffix = this.props.appReducer.mobileMode ? "-mobile" : "";
        this.renderDocumentTitle();

        return (
            <div>
                <Navigation />
                <div className={"content-container" + mobileSuffix}>
                    {this.renderForm()}
                    {this.renderOrderSuccess()}
                </div>
                <Footer
                    forMobile={this.props.appReducer.mobileMode}
                />
            </div>
        );

    }
}

const mapStateToProps = (state) => {
    return {
        appReducer: state.app
    }
}

const mapDispatchToProps = dispatch => {
    return {
        dispatchSetOrderData: (orderData) => {
            dispatch(requestSetOrderData(orderData));
        },
        dispatchSetPage: (pageName) => {
            dispatch(requestSetCurrentPage(pageName));
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderRecord);