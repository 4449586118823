import React from "react";
import {connect} from "react-redux";
import appConfig from "../../appConfig";
import FeedbackMessage from "../FeedbackMessage";
import UserButton from "../inputs/UserButton";
import {DataReporter} from "../../DataReporter";
import "../../styles/components/headline_section.scss";

class HeadlineSection extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            glowSearch: false,
        }
    }

    getProductWord(capitalize=false, plural=false) {
        let productWord = plural ? "products" : "product";

        if (this.props.appReducer.activePersona === appConfig.personas.watches) {
            if (plural) productWord = "watches";
            else productWord = "watch";
        }
        if (capitalize) {
            return productWord.charAt(0).toUpperCase() + productWord.slice(1);
        } else {
            return productWord;
        }
    }

    // Events
    onOrderButtonclick() {
        window.open("/order?t=hls", "_self");
    }

    onInstagramClick() {
        DataReporter.trackMixpanel(this.props, "Header: Open IG");

        setTimeout(() => {
            window.open("https://www.instagram.com/ventrace.watches", "_blank");
        }, 300);
    }

    onYouTubeClick() {
        DataReporter.trackMixpanel(this.props, "Header: Open YT");

        setTimeout(() => {
            window.open("https://www.youtube.com/@ventrace.watches", "_blank");
        }, 300);
    }

    // Renderers
    renderBackgroundVideo() {
        let srcVideo = process.env.PUBLIC_URL + "/assets/video/WatchAR.mp4";
        let srcPoster = process.env.PUBLIC_URL + "/assets/video/WatchAR_poster.jpg";

        if (this.props.forMobile) {
            srcVideo = process.env.PUBLIC_URL + "/assets/video/WatchAR_mobile.mp4";
            srcPoster = process.env.PUBLIC_URL + "/assets/video/WatchAR_mobile_poster.jpg";
        }

        if (appConfig.playVideos) {
            return (
                <video
                    id={"background-video"}
                    poster={srcPoster}
                    autoPlay preload muted loop playsInline>
                    <source
                        src={srcVideo}
                        type="video/mp4"
                    />
                </video>
            );
        } else {
            return (
                <img id={"background-video"}
                     src={srcPoster}
                />
            );
        }
    }

    renderHeadline() {
        let upperText = "The Real Identity";
        let lowerText = `of Your ${this.getProductWord(true)}`

        if (this.props.salesMode && this.props.salesMode === "l") {
            upperText = "Find Your"
            lowerText = `Missing ${this.getProductWord(true)}`
        }
        else if (this.props.salesMode && this.props.salesMode === "v") {
            upperText = "Discover Your"
            lowerText = `Vintage Watch`
        }

        return (
            <div id={"headline-container"}>
                <p id={"level-up"} className={"headline"}>
                    {upperText}
                </p>
                <p className={"headline"}>
                    {lowerText}
                </p>
            </div>
        );
    }

    renderSubheadline() {
        let subheadline = (
            <p id={"platform"}>
                Ventrace gives your {this.getProductWord()} a verified digital record,
                documenting every chapter of its life.
            </p>
        );
        return subheadline;
    }

    renderInstantBenefit() {
        let instantBenefit = (
            <p id={"instant-benefit"}>
                Know exactly what your watch has been through. Every service, every detail, every previous owner
                - all validated by a worldwide network of watchmakers and dealers.
                Your watch's heritage, secured and proven.
            </p>
        );

        if (this.props.salesMode && this.props.salesMode === "l") {
            instantBenefit = (
                <p id={"instant-benefit"}>
                    Ventrace is connected to global network of watch businesses, such as
                    retailers or repair shops. Ventrace can find your lost watch by actively
                    scanning each business transaction for your lost timepiece.
                </p>
            );
        }
        else if (this.props.salesMode && this.props.salesMode === "v") {
            instantBenefit = (
                <p id={"instant-benefit"}>
                    Ventrace is connected to global network of watch businesses, such as
                    retailers or repair shops. Ventrace can collect various pieces of information
                    about the previous history of your vintage watch.
                </p>
            );
        }

        return instantBenefit;
    }

    renderErrorMessage() {
        if (this.props.errorMessage !== null) {
            return <FeedbackMessage
                centered={true}
                success={false}
                message={this.props.errorMessage}
            />;
        }
    }

    renderOrderButton() {
        return (
            <div id={"order-button-container"}>
                <UserButton
                    forMobile={this.props.appReducer.mobileMode}
                    id={"order-button"}
                    value={"Get Your Digital Certificate"}
                    onClick={() => this.onOrderButtonclick()}
                />
            </div>
        );
    }

    renderSocialMediaButtons() {
        return (
            <div id={"social-media-container"}>
                <p id={"t"}>As featured in watch media</p>
                <div class={"inline"} id={"links"}>
                    <div id={"ig-container"} onClick={() => this.onInstagramClick()}>
                        <img id={"ig-logo"}
                             src={process.env.PUBLIC_URL + "/Instagram_icon.png"}
                             alt={"ig-logo"}
                        />
                        <p className={"sm-name"} onClick={() => this.onInstagramClick()}>
                            Instagram
                        </p>
                    </div>

                    <div id={"yt-container"} onClick={() => this.onYouTubeClick()}>
                        <img id={"yt-logo"}
                             src={process.env.PUBLIC_URL + "/YouTube_icon.png"}
                             alt={"yt-logo"}
                        />
                        <p className={"sm-name"} onClick={() => this.onYouTubeClick()}>
                            YouTube
                        </p>
                    </div>
                </div>
            </div>
        )
    }

    render() {
        let mobileSuffix = this.props.forMobile ? "-mobile" : "";
        let ventraceExplainerClass = "headline-package";

        if (this.props.forMobile) {
            ventraceExplainerClass += mobileSuffix;
        }

        return (
            <div id={ventraceExplainerClass}>
                {this.renderBackgroundVideo()}
                <div id={"content"}>
                    {this.renderHeadline()}

                    {this.renderSubheadline()}
                    {this.renderInstantBenefit()}
                    {this.renderOrderButton()}
                    {this.renderSocialMediaButtons()}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        walletReducer: state.wallet,
        blockchainReducer: state.blockchain,
        appReducer: state.app
    }
}

const mapDispatchToProps = dispatch => {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(HeadlineSection);