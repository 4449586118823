import React from "react";
import {connect} from "react-redux";
import appConfig from "../../appConfig";
import {isMobile} from 'react-device-detect';
import {DataReporter} from "../../DataReporter";
import { GrYoutube } from "react-icons/gr";
import "../../styles/components/findstolensection.scss";

class FindStolenSection extends React.Component {
    getProductWord(capitalize) {
        let productWord = "product";
        if (this.props.appReducer.activePersona === appConfig.personas.watches) {
            productWord = "watch";
        }
        if (capitalize) {
            return productWord.charAt(0).toUpperCase() + productWord.slice(1);
        } else {
            return productWord;
        }
    }

    // Events
    onClickYouTubeLink() {
        DataReporter.trackMixpanel(this.props,
            "Find stolen: Clicked YouTube link", {
            category: "Interaction"
        });

        let target = "_blank";
        if (this.props.forMobile) {
            target = "_self";
        }

        window.open("https://youtube.com/shorts/8yS7B4wqw3o", target);
    }

    // Renderers
    renderExplainerDiagram() {
        if (this.props.forMobile) {
            return (
                <div>
                    <img
                        id="explainer-diagram"
                        src={process.env.PUBLIC_URL + '/landingPage/FindStolenExplainer_mobile_watches.png'}
                        alt={"Find Stolen Explainer"}
                    />
                </div>
            );
        } else {
            return (
                <div>
                    <img
                        id="explainer-diagram"
                        src={process.env.PUBLIC_URL + '/landingPage/FindStolenExplainer_desktop_watches.png'}
                        alt={"Find Stolen Explainer"}
                    />
                </div>
            );
        }

    }

    render() {
        let findStolenClassName = "find-stolen-container";
        if (isMobile) {
            findStolenClassName += "-mobile";
        }

        return (
            <div className={findStolenClassName}>
                <div id={"content"}>
                    <p id={"headline"}>
                        Find a <span className={"hl"}>Missing</span> {this.getProductWord(true)} Anywhere
                    </p>

                    {this.renderExplainerDiagram()}

                    <div id={"descr"}>
                        Your digital certificate acts as a global recovery tool for <b>lost</b> or <b>stolen</b> watches.
                        <br/><br/>
                        Ventrace maintains connections with watch retailers and repair shops worldwide.
                        If your watch surfaces anywhere in our network, you'll know immediately through automatic serial number matching.
                    </div>

                    <p id={"see-on-yt"} onClick={() => this.onClickYouTubeLink()}>
                        <GrYoutube id={"icon"} /> See how it works
                    </p>

                </div>

            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        appReducer: state.app,
        walletReducer: state.wallet
    }
}


const mapDispatchToProps = dispatch => {
    return {
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FindStolenSection);