import React from "react";
import {connect} from "react-redux";
import appConfig from "../../appConfig";
import {isMobile} from 'react-device-detect';
import {DataReporter} from "../../DataReporter";
import {GrYoutube} from "react-icons/gr";
import "../../styles/components/checkauthentic_section.scss";

class CheckAuthenticSection extends React.Component {
    getProductWord(capitalize) {
        let productWord = "product";
        if (this.props.appReducer.activePersona === appConfig.personas.watches) {
            productWord = "watch";
        }
        if (capitalize) {
            return productWord.charAt(0).toUpperCase() + productWord.slice(1);
        } else {
            return productWord;
        }
    }

    // Events
    onClickJBLExample() {
        DataReporter.trackMixpanel(this.props, "Clicked example: JBL", {
            category: "Interaction"
        });

        let rp = "0xE5D6125B6D69238EAA9599C8D72CE0917AD9651404D83F746F0469EF32E62C47";
        window.open(appConfig.currentConfig.currentDomain + "/record?rp=" + rp, "_blank");
    }

    onClickSeikoExample() {
        DataReporter.trackMixpanel(this.props, "Clicked example: Seiko Presage");


        let rp = "RPD0HZDQSSZNNRIIYRDMB9398";
        window.open(appConfig.currentConfig.currentDomain + "/record?rp=" + rp, "_blank");
    }

    onClickRolexExample() {
        DataReporter.trackMixpanel(this.props, "Clicked example: Rolex");

        let rp = "QIXPB0JKWLKL972PPHVC70876";
        window.open(appConfig.currentConfig.currentDomain + "/record?rp=" + rp, "_blank");
    }

    onClickAquanautExample() {
        DataReporter.trackMixpanel(this.props, "Clicked example: Aquanaut");

        let rp = "798HKQMYTTXJVJURVQB9Q7923";
        window.open(appConfig.currentConfig.currentDomain + "/record?rp=" + rp, "_blank");
    }

    onClickYouTubeLink() {
        DataReporter.trackMixpanel(this.props,
            "Check Authentic: Clicked YouTube link", {
                category: "Interaction"
            });

        let target = "_blank";
        if (this.props.forMobile) {
            target = "_self";
        }

        window.open("https://youtube.com/shorts/65fVdaH8cMo", target);
    }

    // Renderers
    renderExamples() {
        if (this.props.appReducer.activePersona === appConfig.personas.watches) {
            return this.renderWatchExamples();
        } else {
            return this.renderStandardExamples();
        }
    }

    renderStandardExamples() {
        return (
            <div id={"record-examples"}>
                <p id={"h"}>See real customer examples</p>

                <div className={"inline"} id={"examples-container"}>
                    <div className={"example"} id={"jbl"} onClick={() => this.onClickJBLExample()}>
                        <img id={"mb-example-img"} className={"img-bg"}
                             src={process.env.PUBLIC_URL + "/landingPage/Macbook_Example.png"} alt={"macbook example img"}
                        />
                        <div className={"inner"}>
                            <p className={"cat"}>Laptop</p>
                            <p className={"name"}>Apple Macbook Pro</p>
                        </div>
                    </div>

                    <div className={"example"} id={"jbl"} onClick={() => this.onClickJBLExample()}>
                        <img id={"jbl-example-img"} className={"img-bg"}
                             src={process.env.PUBLIC_URL + "/landingPage/JBL_Example.png"} alt={"jbl example img"}
                        />
                        <div className={"inner"}>
                            <p className={"cat"}>Bluetooth speaker</p>
                            <p className={"name"}>JBL Xtreme 4</p>
                        </div>
                    </div>

                    <div className={"example"} id={"seiko"} onClick={() => this.onClickSeikoExample()}>
                        <img id={"watch-example-img"} className={"img-bg"}
                             src={process.env.PUBLIC_URL + "/landingPage/Watch_Example.png"} alt={"watch example img"}
                        />
                        <div className={"inner"}>
                            <p className={"cat"}>Watch</p>
                            <p className={"name"}>Seiko Presage</p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    renderWatchExamples() {
        return (
            <div id={"record-examples"}>
                <p id={"h"}>Example Ventrace Records</p>

                <div className={"inline"} id={"examples-container"}>
                    <div className={"example"} id={"jbl"} onClick={() => this.onClickRolexExample()}>
                        <img id={"rolex-example-img"} className={"img-bg"} alt={"Rolex img"}
                             src={process.env.PUBLIC_URL + "/landingPage/Rolex_Example.png"}
                        />
                        <div className={"inner"}>
                            <p className={"cat"}>Rolex</p>
                            <p className={"name"}>Datejust</p>
                        </div>
                    </div>

                    <div className={"example"} id={"ps5"} onClick={() => this.onClickAquanautExample()}>
                        <img id={"aquanaut-example-img"} className={"img-bg"} alt={"Patek img"}
                             src={process.env.PUBLIC_URL + "/landingPage/Patek_Example.png"}
                        />
                        <div className={"inner"}>
                            <p className={"cat"}>Patek Philippe</p>
                            <p className={"name"}>Aquanaut</p>
                        </div>
                    </div>

                    <div className={"example"} id={"seiko"} onClick={() => this.onClickSeikoExample()}>
                        <img id={"seiko-example-img"} className={"img-bg"} alt={"Seiko img"}
                             src={process.env.PUBLIC_URL + "/landingPage/Watch_Example.png"}
                        />
                        <div className={"inner"}>
                            <p className={"cat"}>Seiko</p>
                            <p className={"name"}>Presage</p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        let checkAuthenticClassName = "check-authentic-container";
        if (isMobile) {
            checkAuthenticClassName += "-mobile";
        }

        return (
            <div className={checkAuthenticClassName}>
                <div id={"content"}>
                    <p id={"headline"}>
                        <span className={"hl"}>Know</span> What You <span className={"hl"}>Truly</span> Own
                    </p>
                    <div id={"descr"}>
                        Every verified Ventrace certificate reveals your watch's genuine history and current status.
                        <br /><br />
                        From factory specs to service records, international trades to current market value -
                        access the details that matter.
                    </div>

                    {this.renderExamples()}

                    <p id={"see-on-yt"} onClick={() => this.onClickYouTubeLink()}>
                        <GrYoutube id={"icon"} /> See how it works now!
                    </p>
                </div>

            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        appReducer: state.app,
        walletReducer: state.wallet
    }
}


const mapDispatchToProps = dispatch => {
    return {
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CheckAuthenticSection);