import React from "react";
import {isMobile} from "react-device-detect";
import "../../styles/components/search_infobox.scss";
import "../../styles/components/environment_section.scss";

class EnvironmentSection extends React.Component {
    render() {
        let mobileSuffix = isMobile ? "-mobile" : "";

        return (
            <div className={"environment-package" + mobileSuffix}>
                <div id={"content"}>
                    <p id={"headline"}>Preservation Beyond the Watch</p>
                    <p id={"descr"}>
                        Quality watches deserve to last generations.
                        By maintaining detailed records, Ventrace helps keep working timepieces in circulation
                        and out of landfills. Together, we reduce waste and protect the environment from unnecessary
                        manufacturing of replacement watches.
                    </p>

                    <div id={"planet-img-container"}>
                        <img
                            id="planet-img"
                            src={process.env.PUBLIC_URL + '/assets/Planet.png'}
                            alt={"Planet"}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

export default EnvironmentSection;